<template>
  <v-container :class="$style.login">
    <form
      v-if="login.state.show.login"
      @submit.prevent="login.submitLogin"
      :class="$style.form"
    >
      <v-card elevation="2" outlined :loading="login.state.loading.login">
        <v-card-text>
          <input
            v-model.trim="login.state.input.login.username"
            :class="$style.input"
            name="username"
            type="text"
            placeholder="ユーザー名"
            required
            autocomplete
          />
        </v-card-text>

        <v-card-text>
          <input
            v-model.trim="login.state.input.login.password"
            :class="$style.input"
            name="password"
            type="password"
            placeholder="パスワード"
            required
            autocomplete
          />
        </v-card-text>

        <v-card-text v-if="login.state.errors.length">
          <ul
            :class="$style.errors"
            v-for="(error, key) in login.state.errors"
            :key="key"
          >
            <li>{{ error }}</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="mr-4"
            type="submit"
            color="primary"
            :disabled="login.state.disabled.login"
            block
            plain
            >ログイン</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>

    <form
      v-if="login.state.show.newPassword"
      @submit.prevent="login.submitNewPassword"
      :class="$style.form"
    >
      <v-card elevation="2" outlined :loading="login.state.loading.newPassword">
        <v-card-title>新しいパスワードの設定</v-card-title>
        <v-card-subtitle
          >続行するにはパスワードを変更する必要があります。</v-card-subtitle
        >

        <v-card-text>
          <p>
            パスワードには半角大文字小文字を含む英数を8文字以上で入力してください。
          </p>
        </v-card-text>
        <v-card-text>
          <input
            v-model.trim="login.state.input.newPassword.password"
            :class="$style.input"
            name="password"
            type="password"
            placeholder="新しいパスワード"
            required
            autocomplete
          />
        </v-card-text>

        <v-card-text>
          <input
            v-model.trim="login.state.input.newPassword.confirmPassword"
            :class="$style.input"
            name="confirmPassword"
            type="password"
            placeholder="新しいパスワードを再入力"
            required
            autocomplete
          />
        </v-card-text>

        <v-card-text v-if="login.state.errors.length">
          <ul
            v-for="(error, key) in login.state.errors"
            :key="key"
            :class="$style.errors"
          >
            <li>{{ error }}</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-btn class="mr-4" type="submit" color="primary" block plain>
            パスワードの変更
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import loginStore from '@/store/login'

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter()

    const login = loginStore
    login.setup(router)

    return {
      login,
    }
  },
})
</script>


<style module lang="scss">
@import '~vuetify/lib/styles/styles.sass';
.login {
  display: flex;
  max-width: 100%;
  height: 100%;
  margin: 0;
  align-items: center;
  justify-content: center;
}
.form {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  margin-bottom: 40px;
}
.input {
  border-bottom: 1px solid gray;
  padding: 10px;
  width: 100%;
}
.errors {
  padding: 0;
  margin: 0;
  color: #e53935;
  font-weight: bold;
  list-style: none;
}
</style>
