import { InjectionKey, onBeforeMount, reactive, watch, onMounted } from 'vue'
import { Auth } from 'aws-amplify'
import { Router } from 'vue-router'

import cognitoAuthenticator, { Exceptions } from '@/plugins/auth'

import { BaseStore } from '@/store'

interface LoginInput {
  username: string
  password: string
}

interface NewPasswordInput {
  password: string
  confirmPassword: string
}

interface Input {
  login: LoginInput
  newPassword: NewPasswordInput
}

interface Loading {
  login: boolean
  newPassword: boolean
}

interface Show {
  login: boolean
  newPassword: boolean
}

interface Disabled {
  login: boolean
  newPassword: boolean
}

interface State {
  input: Input
  errors: string[]
  show: Show
  loading: Loading
  disabled: Disabled
}

let $router: Router | undefined

const state = reactive<State>({
  input: {
    login: {
      username: '',
      password: '',
    },
    newPassword: {
      password: '',
      confirmPassword: '',
    },
  },
  errors: [],
  show: {
    login: false,
    newPassword: false,
  },
  loading: {
    login: false,
    newPassword: false,
  },
  disabled: {
    login: false,
    newPassword: true,
  },
})

interface Store {
  state: State
  setup: (router: Router) => void
  submitLogin: (event: Event) => Promise<void>
  submitNewPassword: (event: Event) => Promise<void>
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()

const redirectHome = () => {
  $router?.replace({ name: 'Home' })
}

const submitLogin = async (): Promise<void> => {
  state.errors = []
  state.loading.login = true

  await cognitoAuthenticator
    .signIn(state.input.login.username, state.input.login.password)
    .then(() => {
      redirectHome()
    })
    .catch((err: Error) => {
      state.loading.login = false

      switch (err.name) {
        case Exceptions.UserNotFoundException:
          state.errors.push('ユーザー名は見つかりませんでした。')
          break
        case Exceptions.NotAuthorizedException:
          state.errors.push('ユーザー名またはパスワードが間違っています。')
          break
        case Exceptions.NewPasswordRequiredException:
          // 新しいパスワードの設定が必要
          state.show.login = false
          state.show.newPassword = true
          break
        default:
          console.error(err)
          break
      }
    })
}

const validationNewPassword = (input: NewPasswordInput): boolean => {
  state.errors = []

  if (input.password !== input.confirmPassword) {
    state.errors.push(
      '新しいパスワードとパスワードの再入力が一致していません。',
    )
  }

  return state.errors.length == 0
}

const submitNewPassword = async (): Promise<void> => {
  if (!validationNewPassword(state.input.newPassword)) {
    return
  }

  state.loading.newPassword = true

  await cognitoAuthenticator
    .completeNewPassword(state.input.newPassword.password)
    .then(() => {
      redirectHome()
    })
    .catch((err: Error) => {
      state.loading.newPassword = false

      switch (err.name) {
        case Exceptions.InvalidPasswordException:
          state.errors.push(
            'パスワードには半角大文字小文字を含む英数を8文字以上で入力してください。',
          )
          break
        default:
          console.error(err)
          break
      }
    })
}

const watchInputLogin = (input: LoginInput): void => {
  state.disabled.login = !(
    input.username.length > 0 && input.password.length > 0
  )
}

const watchInputNewPassword = (input: NewPasswordInput): void => {
  state.disabled.newPassword = !(
    input.password.length > 0 && input.confirmPassword.length > 0
  )
}

// セットアップ
const setup = (router: Router): void => {
  $router = router

  // セッションが有効な場合、ホーム画面へ遷移する
  onBeforeMount(async () => {
    await router.isReady()
    Auth.currentAuthenticatedUser()
      .then(() => {
        redirectHome()
      })
      .catch(() => {
        // セッションがないので、ログインフォームを表示する
        state.show.login = true
      })
  })

  onMounted(() => {
    watchInputLogin(state.input.login)
  })

  // 入力監視
  watch(state.input.login, watchInputLogin)
  watch(state.input.newPassword, watchInputNewPassword)
}

const store: BaseStore<S> = {
  key,
  state,
  setup,
  submitLogin,
  submitNewPassword,
}

export default store
