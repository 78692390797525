import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const Exceptions = {
  NoSessionException: 'NoSessionException',
  UserNotFoundException: 'UserNotFoundException',
  NotAuthorizedException: 'NotAuthorizedException',
  InvalidPasswordException: 'InvalidPasswordException',
  NewPasswordRequiredException: 'NewPasswordRequiredException',
} as const;

interface User extends CognitoUser {
  challengeName?: string;
}

class CognitoException extends Error {
  constructor(public name: string, message?: string) {
    super(message);
  }
}

class CognitoAuthenticator {
  private user?: User;

  signIn(username: string, password: string) {
    return new Promise<CognitoUser>((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user: User) => {
          // 新しいパスワードが必要
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.user = user;
            reject(
              new CognitoException(Exceptions.NewPasswordRequiredException),
            );
          } else {
            resolve(user);
          }
        })
        .catch(reject);
    });
  }

  signOut() {
    return Auth.signOut();
  }

  completeNewPassword(newPassword: string) {
    return new Promise<User>((resolve, reject) => {
      if (this.user) {
        Auth.completeNewPassword(this.user, newPassword)
          .then(resolve)
          .catch(reject);
      } else {
        reject(new CognitoException(Exceptions.NoSessionException));
      }
    });
  }
}

const cognitoAuthenticator = new CognitoAuthenticator();

export default cognitoAuthenticator;
