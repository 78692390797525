
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import loginStore from '@/store/login'

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter()

    const login = loginStore
    login.setup(router)

    return {
      login,
    }
  },
})
